/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery-bar-rating@1.2.2/dist/themes/bars-1to10.min.css
 * - /npm/jquery-bar-rating@1.2.2/dist/themes/bars-horizontal.min.css
 * - /npm/jquery-bar-rating@1.2.2/dist/themes/bars-movie.min.css
 * - /npm/jquery-bar-rating@1.2.2/dist/themes/bars-pill.min.css
 * - /npm/jquery-bar-rating@1.2.2/dist/themes/bars-reversed.min.css
 * - /npm/jquery-bar-rating@1.2.2/dist/themes/bootstrap-stars.min.css
 * - /npm/jquery-bar-rating@1.2.2/dist/themes/css-stars.min.css
 * - /npm/jquery-bar-rating@1.2.2/dist/themes/fontawesome-stars.min.css
 * - /npm/jquery-bar-rating@1.2.2/dist/themes/fontawesome-stars-o.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
